<!--
@component
Subheading component to be used above a paragraph or list of items.
-->

<script lang="ts">
	export let classes = "";
	export let href: string | null = null;
	export let id: string | null = null;
	export let tag = "h3";
</script>

<template lang="pug">
	svelte:element.font-medium.leading-snug.opacity-90(
		class!="text-[1.05em] hover:opacity-100 { classes }",
		href!="{ href }",
		id!="{ id }",
		this!="{ tag }"
	)
		slot
</template>
